<template>
  <div class="select-field">
    <div class="group">
      <span class="title">省份地区</span>
      <a-button class="btn" type="primary" @click="initProvince">
        全部
      </a-button>
      <a-select
        class="select"
        v-model="provinceId"
        style="width: 200px"
        @change="changeProvince"
      >
        <a-select-option v-for="item in provinceList" :key="item">
          {{ item }}
        </a-select-option>
      </a-select>
    </div>
    <div class="group">
      <span class="title">行业分类</span>
      <a-button class="btn" type="primary" @click="initIndustry">
        全部
      </a-button>
      <a-select class="select" style="width: 200px" @change="changeIndustry">
        <a-select-option v-for="item in industryList" :key="item.induName">
          {{ item.induName }}
        </a-select-option>
      </a-select>
    </div>
    <div class="group">
      <p class="order">排序</p>
      <p
        class="order-item"
        :class="activeOrder === item.value ? 'active' : ''"
        v-for="item in orderList"
        :key="item.value"
        @click="changeOrder(item.value)"
      >
        <a href="javascript:void(0)">{{ item.label }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import { queryProvince, queryIndustry } from "@/httpsAPI/resources/common";
export default {
  data() {
    return {
      provinceList: [],
      provinceId: "",
      industryList: [],
      industryId: "",
      orderList: [
        {
          label: "默认",
          value: "",
        },
        {
          label: "好评",
          value: "好评",
        },
        {
          label: "最近新增",
          value: "最近新增",
        },
      ],
      activeOrder: "",
    };
  },
  mounted() {
    this.queryProvince();
    this.queryIndustry();
  },
  methods: {
    queryProvince() {
      queryProvince().then((res) => {
        if (res.code === "10000") {
          this.provinceList = res.data;
        }
      });
    },
    queryIndustry() {
      queryIndustry().then((res) => {
        if (res.code === "10000") {
          this.industryList = res.data;
        }
      });
    },
    initProvince() {
      this.provinceId = "";
      this.changeProvince();
    },
    changeProvince(value) {
      this.provinceId = value;
      this.$emit("childProvinceId", this.provinceId);
    },
    initIndustry() {
      this.industryId = "";
      this.changeIndustry();
    },
    changeIndustry(value) {
      this.industryId = value;
      this.$emit("childIndustryId", this.industryId);
    },
    changeOrder(id) {
      this.activeOrder = id;
      this.$emit("childOrderId", this.activeOrder);
    },
  },
};
</script>

<style lang="less" scoped>
.select-field {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 32px;
    line-height: 32px;
    & > .title {
      margin-right: 10px;
      width: 90px;
      text-align: right;
    }
    & > .btn {
      margin-right: 10px;
    }
    & > .order-item {
      height: 32px;
      line-height: 32px;
      margin-left: 10px;
      & > a {
        display: inline-block;
        color: #999;
        height: 32px;
        line-height: 32px;
      }
      &:hover,
      &.active {
        & > a {
          color: #0882f5;
        }
      }
    }
    & > .order {
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
