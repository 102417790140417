import request from "@/utils/request";

// 专家列表
export function list(data) {
  return request({
    url: "/resources/queryExpertList",
    method: "post",
    data,
  });
}

// 专家详情
export function view(data) {
  return request({
    url: "/resources/queryExpertDetail",
    method: "post",
    data
  });
}
